import { handleError } from "@/utils";
import { computed, ref } from "vue";
import * as configCat from "configcat-js";

import type { FeatureName } from "@/types";
import { activeFlags } from "@/config";
import { useAuthStore } from "@/stores/userAuth";

const featureFlags = ref<Record<FeatureName, boolean>>({
    isNewQuoteFlowEnabled: false,
    isQuotePriceTooltipAEnabled: false,
    isingredientinsightsenabled: false,
    isgmailaddonenabled: false,
    isAppOrderEnabled: false,
});

export const useFeatureFlag = () => {
    const SDK_KEY = import.meta.env.VITE_CONFIGCAT_KEY || "";
    const configCatClient = configCat.getClient(SDK_KEY);
    const authStore = useAuthStore();

    const loading = computed(() => !!authStore.loaded);

    if (!import.meta.env.VITE_CONFIGCAT_KEY) {
        configCatClient.setOffline();
    }

    const delay = (ms: number) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    const checkFeatureAvailability = async (
        flagName: FeatureName | string
    ): Promise<boolean> => {
        if (!authStore.loaded) {
            await delay(500);
            return checkFeatureAvailability(flagName);
        }

        if (!authStore.companyData?.uuid || !authStore.userData?.email)
            return false;

        const userObject = new configCat.User(
            authStore.companyData.uuid,
            authStore.userData.email
        );

        try {
            return await configCatClient.getValueAsync(
                flagName,
                false,
                userObject
            );
        } catch (e) {
            handleError(e);
            return false;
        }
    };

    /**
     * Loops through active flags and checks their availability.
     */
    const checkActiveFlags = async () => {
        if (!activeFlags.length) return;

        const checks = activeFlags.map(async (flagName) => ({
            flagName,
            isFeatureAvailable: await checkFeatureAvailability(flagName),
        }));

        const results = await Promise.all(checks);

        results.forEach((result) => {
            featureFlags.value[result.flagName] = result.isFeatureAvailable;
        });
    };

    const initializeFeatureFlags = async () => {
        await checkActiveFlags();
    };

    const isFeatureFlagEnabled = (flagName: FeatureName) =>
        featureFlags.value[flagName];

    return {
        loading,
        isFeatureFlagEnabled,
        initializeFeatureFlags,
        checkFeatureAvailability,
    };
};
