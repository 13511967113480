import { ref } from "vue";
import * as yup from "yup";
import OrderService from "@/services/Order";
import type { SupplierAppOrderDetails } from "@/types/api/data-contracts";
import { handleError } from "@/utils";

interface EditPriceFormData {
    shipping_price_in_supplier_currency: number;
    line_items: {
        uuid: string;
        quantity: string;
        supplier_unit_price_in_supplier_currency: number;
    }[];
}

const editPriceSchema = yup.object().shape({
    shipping_price_in_supplier_currency: yup
        .number()
        .integer()
        .min(1)
        .required(),

    line_items: yup.array().of(
        yup.object().shape({
            uuid: yup.string().required(),
            quantity: yup.number().integer().min(1).required(),
            supplier_unit_price_in_supplier_currency: yup
                .number()
                .integer()
                .min(1)
                .required(),
        })
    ),
});

export function useSupplierEditOrderPrices(order: SupplierAppOrderDetails) {
    const formData = ref<EditPriceFormData>({
        shipping_price_in_supplier_currency: null as unknown as number,
        line_items: [],
    });

    const isLoading = ref(false);
    const isSubmitted = ref(false);
    const orderService = OrderService.getInstance();
    const fieldErrors = ref<Record<string, boolean>>({});

    const initializeForm = () => {
        formData.value = {
            shipping_price_in_supplier_currency:
                order.shipping_price_in_supplier_currency,
            line_items: order.line_items.map((item) => ({
                uuid: item.uuid,
                quantity: item.quantity,
                supplier_unit_price_in_supplier_currency:
                    item.supplier_unit_price_in_supplier_currency,
            })),
        };
    };

    const validateForm = async () => {
        try {
            await editPriceSchema.validate(formData.value, {
                abortEarly: false,
            });
            fieldErrors.value = {};
            return true;
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const errors: Record<string, boolean> = {};

                error.inner.forEach((err) => {
                    if (err.path) {
                        errors[err.path] = true;
                    }
                });

                fieldErrors.value = errors;
            }
            return false;
        }
    };

    const hasFieldError = (fieldPath: string) =>
        isSubmitted.value && fieldErrors.value[fieldPath] === true;

    const handleSubmit = async () => {
        isLoading.value = true;
        isSubmitted.value = true;
        const isValid = await validateForm();

        if (isValid) {
            try {
                await orderService.updateAppOrderPrices(
                    order.uuid,
                    formData.value
                );
                isLoading.value = false;
                return true;
            } catch (error) {
                handleError(error);
            }
        }

        isLoading.value = false;
        return false;
    };

    return {
        formData,
        isLoading,
        isSubmitted,
        initializeForm,
        hasFieldError,
        handleSubmit,
    };
}
