import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/userAuth";
import { isValidRoute } from "@/utils";
import { CompanyTypeEnum } from "@/types/api/data-contracts";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "Home Page",
            redirect: "/signin",
        },
        {
            path: "/signup/coming-soon",
            name: "coming-soon",
            component: () => import("@/views/common/ComingSoonView.vue"),
            // We only want to show this page after a user has registered with a country
            // not being GB and signing in anyways
        },
        {
            path: "/signin",
            name: "Signin",
            component: () => import("@/views/common/SigninView.vue"),
            meta: { requiresunAuth: true },
        },
        {
            path: "/password-reset",
            name: "Reset Password Init",
            component: () => import("@/views/common/ResetPasswordInit.vue"),
            meta: { requiresunAuth: true },
        },
        {
            path: "/password-reset/commit/:resetToken",
            name: "Reset Password Commit",
            component: () => import("@/views/common/ResetPasswordCommit.vue"),
            props: true,
            meta: { requiresunAuth: true },
        },
        {
            path: "/password-reset/commit-onboarding/:resetToken",
            name: "Reset Password Onboarding Commit",
            component: () =>
                import("@/views/common/ResetPasswordOnboarding.vue"),
            props: true,
            meta: { requiresunAuth: true },
        },
        {
            path: "/accept-invite/:inviteId",
            name: "Accept Invite",
            component: () => import("@/views/common/AcceptInvite.vue"),
            props: true,
            meta: { requiresunAuth: true },
        },
        {
            path: "/brands/signup",
            name: "Brand Signup",
            component: () => import("@/views/brands/SignupView.vue"),
            meta: { requiresunAuth: true },
        },
        {
            path: "/suppliers/signup",
            name: "Supplier Signup",
            component: () => import("@/views/suppliers/SignupView.vue"),
            meta: { requiresunAuth: true },
        },
        {
            path: "/settings",
            name: "Settings",
            redirect: "/settings/user",
            component: () => import("@/views/common/settings/SettingsView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "user",
                    name: "User Settings",
                    component: () =>
                        import("@/views/common/settings/UserSettings.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "company",
                    name: "Company Settings",
                    component: () =>
                        import("@/views/common/settings/CompanySettings.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "team",
                    name: "Team Settings",
                    component: () =>
                        import("@/views/common/settings/TeamSettings.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "addresses",
                    name: "Address Settings",
                    component: () =>
                        import("@/views/common/settings/AddressSettings.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "notifications/suppliers",
                    name: "Notification Settings Supplier",
                    component: () =>
                        import("@/views/common/settings/SupplierEmailPref.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "notifications/brands",
                    name: "Notification Settings Brand",
                    component: () =>
                        import("@/views/common/settings/BuyerEmailPref.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "referrals",
                    name: "Referral Settings",
                    component: () =>
                        import("@/views/common/settings/ReferralSettings.vue"),
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/change-password",
            name: "Change Password",
            component: () => import("@/views/common/ChangePassword.vue"),
            meta: { requiresAuth: true },
        },
        {
            path: "/brands/dashboard",
            name: "Brand Dashboard",
            component: () => import("@/views/brands/DashboardView.vue"),
            meta: { requiresAuth: true },
        },
        {
            path: "/brands/requests",
            name: "Brand Inquiries",
            component: () => import("@/views/brands/inquiry/InquiryView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Brand Inquiries Listing",
                    component: () =>
                        import("@/views/brands/inquiry/InquiryListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "create",
                    name: "Brand Inquiry Creation",
                    component: () =>
                        import("@/views/brands/inquiry/InquiryCreation.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:inquiryId",
                    name: "Brand Inquiries Details",
                    component: () =>
                        import("@/views/brands/inquiry/InquiryDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/brands/contracts",
            name: "Brand Contracts",
            component: () => import("@/views/brands/contract/ContractView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Brand Contract Listing",
                    component: () =>
                        import("@/views/brands/contract/ContractListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/quote/:quoteId/",
                    name: "Brand Contract Details",
                    component: () =>
                        import("@/views/brands/contract/ContractDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/brands/quotes",
            name: "Brand Quotes",
            component: () => import("@/views/brands/quote/QuoteView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Brand Quotes Listing",
                    component: () =>
                        import("@/views/brands/quote/QuoteListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:quoteId",
                    name: "Brand Quotes Details",
                    component: () =>
                        import("@/views/brands/quote/QuoteDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/brands/orders",
            name: "Brand Orders",
            component: () => import("@/views/brands/order/OrderView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Brand Order Listings",
                    component: () =>
                        import("@/views/brands/order/OrderListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:orderId",
                    name: "Brand Order Details",
                    component: () =>
                        import("@/views/brands/order/OrderDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: "checkout/:orderId",
                    name: "Brand Quotes Checkout",
                    component: () =>
                        import(
                            "@/views/brands/quote/checkout/CheckoutView.vue"
                        ),
                    props: true,
                    meta: { requiresAuth: true },
                    children: [
                        {
                            path: "",
                            name: "Brand Quotes Checkout Details",
                            props: true,
                            component: () =>
                                import(
                                    "@/views/brands/quote/checkout/CheckoutDetails.vue"
                                ),
                        },
                        {
                            path: "confirmation",
                            name: "Brand Quotes Checkout Confirmation",
                            props: true,
                            component: () =>
                                import(
                                    "@/views/brands/quote/checkout/CheckoutConfirmation.vue"
                                ),
                        },
                    ],
                },
            ],
        },
        {
            path: "/brands/app-orders",
            name: "Brand App Orders",
            component: () => import("@/views/brands/order/OrderView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Brand App Order Listings",
                    component: () =>
                        import("@/views/brands/order/AppOrderListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:orderId",
                    name: "Brand App Order Details",
                    component: () =>
                        import("@/views/brands/order/AppOrderDetails.vue"),
                    meta: { requiresAuth: true },
                    props: true,
                },
            ],
        },

        {
            path: "/brands/ingredients",
            name: "Brand Ingredients",
            component: () =>
                import("@/views/brands/ingredients/IngredientView.vue"),
            meta: { requiresAuth: true, breadcrumb: "Ingredients" },
            children: [
                {
                    path: "",
                    name: "Brand Ingredients Listing",
                    component: () =>
                        import(
                            "@/views/brands/ingredients/IngredientListing.vue"
                        ),
                    meta: { requiresAuth: true, breadcrumb: "Ingredients" },
                },
                {
                    path: "details/:ingredientId&isAssigned=:isAssigned",
                    name: "Brand Ingredients Details",
                    component: () =>
                        import(
                            "@/views/brands/ingredients/IngredientDetails.vue"
                        ),
                    props: true,
                    meta: { requiresAuth: true, breadcrumb: "" },
                },
                {
                    path: "edit/:ingredientId&isAssigned=:isAssigned",
                    name: "Brand Edit Ingredient",
                    component: () =>
                        import("@/views/brands/ingredients/AddIngredient.vue"),
                    props: true,
                    meta: { requiresAuth: true, breadcrumb: "" },
                },
                {
                    path: "add-ingredient",
                    name: "Add Ingredient",
                    component: () =>
                        import("@/views/brands/ingredients/AddIngredient.vue"),
                    meta: { requiresAuth: true, breadcrumb: "Add Ingredient" },
                },
            ],
        },
        {
            path: "/brands/suppliers",
            name: "My Suppliers",
            component: () =>
                import("@/views/brands/my-suppliers/MySuppliersView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "My Suppliers List",
                    component: () =>
                        import("@/views/brands/my-suppliers/SuppliersList.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: ":id/details",
                    name: "My Supplier Details",
                    component: () =>
                        import(
                            "@/views/brands/my-suppliers/SupplierDetails.vue"
                        ),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/brands/basket",
            name: "Basket",
            component: () => import("@/views/brands/basket/BasketView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Basket Details",
                    component: () =>
                        import("@/views/brands/basket/BasketDetails.vue"),
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/suppliers/dashboard",
            name: "Supplier Dashboard",
            component: () => import("@/views/suppliers/DashboardView.vue"),
            meta: { requiresAuth: true },
        },
        {
            path: "/suppliers/products",
            name: "Supplier Product Listings",
            component: () => import("@/views/suppliers/ProductsListing.vue"),
            meta: { requiresAuth: true },
        },
        {
            path: "/suppliers/requests",
            name: "Supplier Inquiries",
            component: () =>
                import("@/views/suppliers/inquiry/InquiryView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Supplier Inquiries Listings",
                    component: () =>
                        import("@/views/suppliers/inquiry/InquiryListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:inquiryId",
                    name: "Supplier Inquiry Details",
                    component: () =>
                        import("@/views/suppliers/inquiry/InquiryDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/suppliers/quotes",
            name: "Supplier Quotes",
            component: () => import("@/views/suppliers/quote/QuoteView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Suppliers Quote Listing",
                    component: () =>
                        import("@/views/suppliers/quote/QuoteListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "create/:inquiryId",
                    name: "Supplier Quote Creation",
                    component: () =>
                        import("@/views/suppliers/quote/QuoteCreation.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:quoteId",
                    name: "Supplier Quote Details",
                    component: () =>
                        import("@/views/suppliers/quote/QuoteDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: "edit/:quoteId",
                    name: "Supplier Edit Quote",
                    component: () =>
                        import("@/views/suppliers/quote/QuoteEdit.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/suppliers/contracts",
            name: "Supplier Contracts",
            component: () =>
                import("@/views/suppliers/contract/ContractView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Supplier Contract Listing",
                    component: () =>
                        import(
                            "@/views/suppliers/contract/ContractListing.vue"
                        ),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/quote/:quoteId/",
                    name: "Supplier Contract Details",
                    component: () =>
                        import(
                            "@/views/suppliers/contract/ContractDetails.vue"
                        ),
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: "contract-creation/:quoteId",
                    name: "Contract Creation",
                    component: () =>
                        import(
                            "@/views/suppliers/contract/ContractCreation.vue"
                        ),
                    props: true,
                    meta: { requiresAuth: true, breadcrumb: "" },
                },
                {
                    path: "edit-creation/:quoteId?=:edit",
                    name: "Edit Contract",
                    component: () =>
                        import(
                            "@/views/suppliers/contract/ContractCreation.vue"
                        ),
                    props: true,
                    meta: { requiresAuth: true, breadcrumb: "" },
                },
            ],
        },
        {
            path: "/suppliers/kyb",
            name: "Suppliers KYB",
            component: () => import("@/views/suppliers/kyb/KybView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Supplier Company Details",
                    component: () =>
                        import("@/views/suppliers/kyb/KybGeneral.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "authorised-user",
                    name: "Supplier Authorised User",
                    component: () =>
                        import("@/views/suppliers/kyb/KybAuthorization.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "shareholders",
                    name: "Supplier Shareholders",
                    component: () =>
                        import("@/views/suppliers/kyb/KybShareholders.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "thank-you",
                    name: "Supplier Thank You",
                    component: () =>
                        import("@/views/suppliers/kyb/KybThankYou.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "get-paid",
                    name: "Supplier Get Paid",
                    component: () =>
                        import("@/views/suppliers/kyb/KybGetPaid.vue"),
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/suppliers/orders",
            name: "Supplier Orders",
            component: () => import("@/views/suppliers/order/OrderView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Supplier Order Listings",
                    component: () =>
                        import("@/views/suppliers/order/OrderListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:orderId",
                    name: "Supplier Order Details",
                    component: () =>
                        import("@/views/suppliers/order/OrderDetails.vue"),
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        {
            path: "/suppliers/app-orders",
            name: "Supplier App Orders",
            component: () => import("@/views/suppliers/order/OrderView.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "Supplier App Order Listings",
                    component: () =>
                        import("@/views/suppliers/order/AppOrderListing.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "details/:orderId",
                    name: "Supplier App Order Details",
                    component: () =>
                        import("@/views/suppliers/order/AppOrderDetail.vue"),
                    meta: { requiresAuth: true },
                    props: true,
                },
                {
                    path: "edit/:orderId",
                    name: "Supplier App Order Edit Prices",
                    component: () =>
                        import(
                            "@/views/suppliers/order/AppOrderEditPrices.vue"
                        ),
                    meta: { requiresAuth: true },
                    props: true,
                },
            ],
        },
        {
            path: "/suppliers/onboarding",
            name: "Supplier Onboarding",
            component: () => import("@/views/suppliers/OnboardingView.vue"),
            meta: { requiresAuth: true },
        },
        {
            path: "/tools/sugar-contract-calculator",
            name: "Sugar Contract Calculator",
            component: () =>
                import(
                    "@/views/tools/SugarContractCalculator/SugarContractCalculator.vue"
                ),
        },
        {
            path: "/tools/opplymiser",
            name: "Opplymiser",
            component: () =>
                import("@/views/tools/Opplymiser/OpplymiserView.vue"),
            children: [
                {
                    path: "",
                    name: "Opplymiser Calculations",
                    component: () =>
                        import("@/views/tools/Opplymiser/Calculations.vue"),
                },
                {
                    path: "results",
                    name: "Opplymiser Results",
                    component: () =>
                        import("@/views/tools/Opplymiser/Results.vue"),
                },
            ],
        },
        {
            path: "/404",
            name: "404 Page",
            component: () => import("@/views/common/404Page.vue"),
        },
        {
            path: "/:notFound(.*)",
            redirect: "/404",
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    // Set page loading to true to show the whole page loader
    authStore.pageLoading = true;

    function getUserType(isBrand: boolean) {
        return isBrand ? CompanyTypeEnum.Brand : CompanyTypeEnum.Supplier;
    }

    function getDashboardRoute(isBrand: boolean) {
        return getUserType(isBrand) === CompanyTypeEnum.Brand
            ? "Brand Dashboard"
            : "Supplier Dashboard";
    }

    // Check if the route requires authentication
    if (to.meta.requiresAuth) {
        // Ensure user data is loaded
        if (!authStore.userData || !authStore.companyData) {
            await authStore.authenticateUser();
        }

        const userType = getUserType(authStore.isBrand);
        const dashboardRoute = getDashboardRoute(authStore.isBrand);

        // Check if user is authenticated and the route is valid for their role
        if (authStore.auth && isValidRoute(to.path, userType)) {
            next();
        } else if (authStore.auth) {
            // User is authenticated but doesn't have access to this route
            next({ name: dashboardRoute }); // Redirect to a default authorized page
        } else {
            // pass the path of redirected page to sign in as a query
            next({
                path: "/signin",
                query: { redirectTo: to.fullPath, ...to.query },
            });
        }
    }
    // Check if the route is for non-authenticated users only
    else if (to.meta.requiresunAuth) {
        // If no auth token exists, allow access immediately
        if (!authStore.token) {
            return next();
        }

        // Skip auth check for admin masquerade
        if (to.query.adminMasqueradeToken) {
            return next();
        }

        try {
            // Only attempt auth if we have a token but missing user data
            if (!authStore.userData || !authStore.companyData) {
                await authStore.authenticateUser();
            }

            // If still authenticated after check, redirect to dashboard
            if (authStore.auth) {
                return next({ name: getDashboardRoute(authStore.isBrand) });
            }
        } catch {
            // On auth failure, reset store and allow access
            authStore.$reset();
        }
        next();
    }
    // For routes that don't require specific auth checks
    else {
        next();
    }
});

router.afterEach(() => {
    const authStore = useAuthStore();
    // Set page loading to false to hide the whole page loader
    authStore.pageLoading = false;
});

export default router;
