import {
    AppOrderPaymentTermsEnum,
    AppOrderStateEnum,
    SupplierPayoutTermsEnum,
} from "./types/api/data-contracts";
import type {
    AppOrderStatusLabelsMap,
    AppOrderPaymentTermsLabelsMap,
    AppOrderSupplierPaymentTermsLabelsMap,
    AppOrderBannerMessagesMap,
} from "./types/AppOrder";

export const INQUIRY_ICON_FALLBACK =
    "https://storage.googleapis.com/v26kbrqn/inquiry/icons/inquiry_default_icon.png";

export const OPPLY_SQUARE_LOGO_ICON = "/images/logo-square.svg";

/**
 * List of supported allergens on platform for inquiry creation/edit
 */
export const supportedAllergens = [
    "celery",
    "cereals_containing_gluten",
    "crustaceans",
    "eggs",
    "fish",
    "lupin",
    "milk",
    "molluscs",
    "mustard",
    "nuts",
    "peanuts",
    "sesame_seeds",
    "soya",
    "sulphur_dioxide",
];

/**
 * List of supported dietary requirements on platform for inquiry creation/edit
 */
export const supportedDietaryRequirements = [
    "kosher",
    "halal",
    "organic",
    "vegan",
    "vegetarian",
];

/**
 * List of price disclaimer points shown on inquiry creation/edit
 */
export const pricingDisclaimerList = [
    "All prices are given in local currency as per your selected country",
    "Target unit price will not be shown to suppliers",
];

/**
 * List of available frequency options on platform for inquiry creation/edit
 */
export const forecastOptions = [
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Annually", value: "annually" },
];

export const frequencyOptions = forecastOptions.map((option) => option.value);

export const HOTJAR_USER_ID = "2939969";

/**
 * height and with for chatbox
 */
export const ChatBoxDimensions = {
    DEFAULT_WIDTH: "496px",
    DEFAULT_HEIGHT: "560px",
    EXPANDED_WIDTH: "calc(100vw - (17% + 80px))",
    EXPANDED_HEIGHT: "calc(100vh - 74px)",
    EXPANDED_WIDTH_WITH_HIDDEN_MENU: "calc(100vw - 80px)",
};

/**
 * list of supported currencies on platform
 */
export const currencyList = [
    { label: "GBP", value: "GBP" },
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
    { label: "CAD", value: "CAD" },
];

export const monthFieldOptions = [
    { label: "1 month", value: 1 },
    { label: "2 months", value: 2 },
    { label: "3 months", value: 3 },
    { label: "4 months", value: 4 },
    { label: "5 months", value: 5 },
    { label: "6 months", value: 6 },
    { label: "7 months", value: 7 },
    { label: "8 months", value: 8 },
    { label: "9 months", value: 9 },
    { label: "10 months", value: 10 },
    { label: "11 months", value: 11 },
    { label: "12 months", value: 12 },
];

export const weekFieldOptions = [
    { label: "Every Week", value: 1 },
    { label: "Every 2 Weeks", value: 2 },
    { label: "Every 3 Weeks", value: 3 },
    { label: "Every 4 Weeks", value: 4 },
    { label: "Every 5 Weeks", value: 5 },
    { label: "Every 6 Weeks", value: 6 },
    { label: "Custom", value: 0 },
];

export const timePeriodOptions = [
    { label: "Days", value: "day" },
    { label: "Months", value: "month" },
    { label: "Years", value: "year" },
];

export const incoterms = [
    {
        label: "Carriage and Insurance Paid to (CIP)",
        value: "carriage_and_insurance_paid_to",
    },
    {
        label: "Carriage Paid To (CPT)",
        value: "carriage_paid_to",
    },
    {
        label: "Cost and Freight (CFR)",
        value: "cost_and_freight",
    },
    {
        label: "Cost, Insurance and Freight (CIF)",
        value: "cost_insurance_and_freight",
    },

    {
        label: "Delivered At Place (DAP)",
        value: "delivered_at_place",
    },
    { label: "Delivery Duty Paid (DDP)", value: "delivery_duty_paid" },
    { label: "Delivery Duty Unpaid (DDU)", value: "delivery_duty_unpaid" },
    {
        label: "Ex Works (EXW)",
        value: "ex_works",
    },
    {
        label: "Free Alongside Ship (FAS)",
        value: "free_alongside_ship",
    },
    {
        label: "Free on Board (FOB)",
        value: "free_on_board",
    },
];

export const feedbackItems = [
    {
        label: "Ingredient Requests",
        href: "https://opply.canny.io/ingredient-requests",
        testId: "feedback-menu__ingredient-request",
    },
    {
        label: "Feature Requests",
        href: "https://opply.canny.io/feature-requests",
        testId: "feedback-menu__feature-request",
    },
];

export const forcedLoginTimestamp = "2025-02-05T00:00:00Z";

export const buyerAppOrderStatusLabels: AppOrderStatusLabelsMap = {
    [AppOrderStateEnum.Initializing]: "Initialising",
    [AppOrderStateEnum.PendingSupplierApproval]: "Pending Opply Review",
    [AppOrderStateEnum.Cancelled]: "Cancelled",
    [AppOrderStateEnum.PendingBuyerApproval]: "Pending Review",
    [AppOrderStateEnum.PendingAdminApproval]: "Pending Opply Review",
    [AppOrderStateEnum.ToBeDispatchedUnpaid]: "Pending Dispatch",
    [AppOrderStateEnum.ToBePaidNow]: "Pending Payment",
    [AppOrderStateEnum.ToBeDeliveredPaymentNotOverdue]: "In Transit",
    [AppOrderStateEnum.ToBeDeliveredPaymentOverdue]: "In Transit",
    [AppOrderStateEnum.ToBeDispatched]: "Pending Dispatch",
    [AppOrderStateEnum.ToBePaidOutToSupplier]: "Pending Dispatch",
    [AppOrderStateEnum.ToBeDelivered]: "In Transit",
    [AppOrderStateEnum.DeliveredPaymentNotOverdue]: "Pending Payment",
    [AppOrderStateEnum.Delivered]: "Completed",
    [AppOrderStateEnum.DeliveredPaymentOverdue]: "Pending Payment",
};

export const supplierAppOrderStatusLabels: AppOrderStatusLabelsMap = {
    [AppOrderStateEnum.Initializing]: "Initialising",
    [AppOrderStateEnum.PendingSupplierApproval]: "Pending Review",
    [AppOrderStateEnum.Cancelled]: "Cancelled",
    [AppOrderStateEnum.PendingBuyerApproval]: "Pending Buyer Review",
    [AppOrderStateEnum.PendingAdminApproval]: "Pending Opply Review",
    [AppOrderStateEnum.ToBeDispatchedUnpaid]: "Pending Dispatch",
    [AppOrderStateEnum.ToBePaidNow]: "Pending Payment",
    [AppOrderStateEnum.ToBeDeliveredPaymentNotOverdue]: "In Transit",
    [AppOrderStateEnum.ToBeDeliveredPaymentOverdue]: "In Transit",
    [AppOrderStateEnum.ToBeDispatched]: "Pending Dispatch",
    [AppOrderStateEnum.ToBePaidOutToSupplier]: "Pending Payment",
    [AppOrderStateEnum.ToBeDelivered]: "In Transit",
    [AppOrderStateEnum.DeliveredPaymentNotOverdue]: "Completed",
    [AppOrderStateEnum.Delivered]: "Completed",
    [AppOrderStateEnum.DeliveredPaymentOverdue]: "Completed",
};

export const appOrderPaymentTermsLabels: AppOrderPaymentTermsLabelsMap = {
    [AppOrderPaymentTermsEnum.PayBeforeDispatch]: "Pay Before Dispatch",
    [AppOrderPaymentTermsEnum.PayAfterDispatch]: "Pay After Dispatch",
};

export const appOrderSupplierPaymentTermsLabels: AppOrderSupplierPaymentTermsLabelsMap =
    {
        [SupplierPayoutTermsEnum.PayoutBeforeDispatch]:
            "Payout Before Dispatch",
        [SupplierPayoutTermsEnum.PayoutAfterDispatch]: "Payout After Dispatch",
    };

export const buyerAppOrderBannerMessages: AppOrderBannerMessagesMap = {
    [AppOrderStateEnum.Initializing]:
        "We’re getting your order ready. You’ll be able to review it shortly.",
    [AppOrderStateEnum.PendingSupplierApproval]:
        "We're checking your order details. We'll let you know once it's approved.",
    [AppOrderStateEnum.Cancelled]:
        "This order has been cancelled and will not proceed further.",
    [AppOrderStateEnum.PendingBuyerApproval]:
        "Please review the final cost and click Approve to continue with your order.",
    [AppOrderStateEnum.PendingAdminApproval]:
        "We're checking your order details. We'll let you know once it's approved.",
    [AppOrderStateEnum.ToBeDispatchedUnpaid]:
        "The supplier is preparing your order for dispatch. You’ll receive an invoice and tracking details once it’s sent.",
    [AppOrderStateEnum.ToBePaidNow]:
        "To proceed with your order, please complete the payment.",
    [AppOrderStateEnum.ToBeDeliveredPaymentNotOverdue]:
        "Your order is on the way. Please mark it as delivered once it arrives.",
    [AppOrderStateEnum.ToBeDeliveredPaymentOverdue]:
        "Your order is on the way, but payment is overdue. Please pay as soon as possible.",
    [AppOrderStateEnum.ToBeDispatched]:
        "Your order is confirmed, and payment has been received. The supplier will dispatch it shortly.",
    [AppOrderStateEnum.ToBePaidOutToSupplier]:
        "Your order is confirmed, and payment has been received. The supplier will dispatch it shortly.",
    [AppOrderStateEnum.ToBeDelivered]:
        "Your order has been dispatched and is on its way. Please mark it as delivered once it arrives.",
    [AppOrderStateEnum.DeliveredPaymentNotOverdue]:
        "Your order has been delivered. Please complete your payment by the due date to finalise it.",
    [AppOrderStateEnum.Delivered]: "Your order is complete. ",
    [AppOrderStateEnum.DeliveredPaymentOverdue]:
        "Your order has been delivered, but payment is still due. Please complete it as soon as possible.",
};

export const supplierAppOrderBannerMessages: AppOrderBannerMessagesMap = {
    [AppOrderStateEnum.Initializing]:
        "We’re getting your order ready. You’ll be able to review it shortly.",
    [AppOrderStateEnum.PendingSupplierApproval]:
        "Please review the order details. You can approve them as they are, or make any necessary changes.",
    [AppOrderStateEnum.Cancelled]:
        "This order has been cancelled. No further action is needed.",
    [AppOrderStateEnum.PendingBuyerApproval]:
        "The buyer is reviewing your proposed updates. We’ll notify you when they respond.",
    [AppOrderStateEnum.PendingAdminApproval]:
        "We’re reviewing this order. You’ll be informed once it’s approved.",
    [AppOrderStateEnum.ToBeDispatchedUnpaid]:
        "You can now dispatch the order. The buyer will be invoiced after it’s sent.",
    [AppOrderStateEnum.ToBePaidNow]:
        "Please wait for the buyer to complete payment before dispatching.",
    [AppOrderStateEnum.ToBeDeliveredPaymentNotOverdue]:
        "The order is on its way to the buyer!",
    [AppOrderStateEnum.ToBeDeliveredPaymentOverdue]:
        "The order is on its way to the buyer!",
    [AppOrderStateEnum.ToBeDispatched]:
        "The order has been paid. Please dispatch the order to complete fulfilment.",
    [AppOrderStateEnum.ToBePaidOutToSupplier]:
        "The buyer has paid. Your payment will be processed shortly.",
    [AppOrderStateEnum.ToBeDelivered]: "The order is on its way to the buyer!",
    [AppOrderStateEnum.DeliveredPaymentNotOverdue]:
        "The order has been delivered. We're waiting for the buyer’s final payment.",
    [AppOrderStateEnum.Delivered]: "The order is complete.",
    [AppOrderStateEnum.DeliveredPaymentOverdue]:
        "The order has been delivered. We're waiting for the buyer’s final payment.",
};
