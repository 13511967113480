import axiosInstance from "@/services/axiosInstance";
import { useEvents } from "@/composables";

import type {
    Ingredient,
    PaginatedInquiryDocumentList,
    PaginatedIngredientList,
} from "@/types/api/data-contracts";
import type { AxiosPromise } from "axios";

class IngredientService {
    // eslint-disable-next-line no-use-before-define
    private static instance: IngredientService;

    public static getInstance(): IngredientService {
        if (!IngredientService.instance) {
            IngredientService.instance = new IngredientService();
        }
        return IngredientService.instance;
    }

    createIngredient(payload: Ingredient): AxiosPromise<Ingredient> {
        const url = `inquiries/ingredients/`;

        const createIngredientPromise = axiosInstance.post(url, payload);

        useEvents().emitEvent("createIngredientRequested", {
            createIngredientPromise,
        });

        return createIngredientPromise;
    }

    uploadDocument(ingredientUuid: string, payload: FormData): AxiosPromise {
        const url = `inquiries/ingredients/${ingredientUuid}/upload/`;
        return axiosInstance.post(url, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    getDocuments(
        ingredientUuid: string
    ): AxiosPromise<PaginatedInquiryDocumentList> {
        const url = `inquiries/ingredients/${ingredientUuid}/documents/`;
        return axiosInstance.get(url);
    }

    getIngredient(ingredientUuid: string): AxiosPromise<Ingredient> {
        const url = `inquiries/ingredients/${ingredientUuid}/`;
        return axiosInstance.get(url);
    }

    getAssignIngredient(ingredientUuid: string): AxiosPromise<Ingredient> {
        const url = `inquiries/ingredients/${ingredientUuid}/retrieve-assigned-ingredient/`;
        return axiosInstance.get(url);
    }

    getListings(
        filter?: {
            name?: string | null;
            page?: number | undefined;
            product_uuid?: string | undefined;
        },
        ordering?: string | null
    ): AxiosPromise<PaginatedIngredientList> {
        return axiosInstance.get("inquiries/ingredients/", {
            params: {
                ...filter,
                ordering,
            },
        });
    }

    deleteIngredient(uuid: string): AxiosPromise {
        const url = `inquiries/ingredients/${uuid}/`;
        return axiosInstance.delete(url);
    }

    editIngredient(
        uuid: string,
        payload: Ingredient
    ): AxiosPromise<Ingredient> {
        const url = `inquiries/ingredients/${uuid}/`;
        return axiosInstance.put(url, payload);
    }

    deleteDocument(ingredientId: string, documentIds: string[]) {
        const url = `/inquiries/ingredients/${ingredientId}/delete-documents/`;

        const payload = {
            uuids: documentIds,
        };

        return axiosInstance.delete(url, { data: payload });
    }
}

export default IngredientService;
