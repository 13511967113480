import axiosInstance from "@/services/axiosInstance";
import type {
    AddressTypeEnum,
    BrandDashboardCount,
    PaginatedCompanyAddressList,
    CompanyDocument,
} from "@/types/api/data-contracts";
import type { AxiosPromise } from "axios";

class CompanyService {
    // eslint-disable-next-line no-use-before-define
    private static instance: CompanyService;

    public static getInstance(): CompanyService {
        if (!CompanyService.instance) {
            CompanyService.instance = new CompanyService();
        }
        return CompanyService.instance;
    }

    getInfo() {
        return axiosInstance.get("companies/info/");
    }

    addAddress(payload: object) {
        return axiosInstance.post("companies/addresses/", payload);
    }

    updateAddress(addressId: number, payload: object) {
        return axiosInstance.put(`companies/addresses/${addressId}/`, payload);
    }

    getAddresses(
        page?: number,
        addressType?: `${AddressTypeEnum}`
    ): AxiosPromise<PaginatedCompanyAddressList> {
        return axiosInstance.get("companies/addresses/", {
            params: { page, address_type: addressType },
        });
    }

    getDeliveryAddress(id: string): AxiosPromise {
        const url = `/inquiries/delivery_address/${id}`;
        return axiosInstance.get(url);
    }

    updateDeliveryAddress(id: string, payload: any): AxiosPromise {
        const url = `/inquiries/delivery_address/${id}`;
        return axiosInstance.put(url, payload);
    }

    getDispatchAddress(id: string | number): AxiosPromise {
        const url = `/inquiries/dispatch_address/${id}`;
        return axiosInstance.get(url);
    }

    updateDispatchAddress(id: string, payload: any): AxiosPromise {
        const url = `/inquiries/dispatch_address/${id}`;
        return axiosInstance.put(url, payload);
    }

    getAddressDetails(addressId: number) {
        return axiosInstance.get(`companies/addresses/${addressId}/`);
    }

    deleteAddress(addressId: number) {
        return axiosInstance.delete(`companies/addresses/${addressId}/`);
    }

    getDashboardStats(): AxiosPromise<BrandDashboardCount> {
        return axiosInstance.get("companies/brands/dashboard/count/");
    }

    inviteBrand(payload: object) {
        return axiosInstance.post(`companies/invite-company/`, {
            ...payload,
            referred_company_type: "brand",
            signup_link: import.meta.env.VITE_BASE_URL as string,
        });
    }

    inviteSupplier(payload: object) {
        return axiosInstance.post(`companies/invite-company/`, {
            ...payload,
            referred_company_type: "supplier",
            signup_link: import.meta.env.VITE_BASE_URL as string,
        });
    }

    addCustomer(payload: object) {
        return axiosInstance.post("companies/customers/", payload);
    }

    uploadDocument(payload: FormData): AxiosPromise<CompanyDocument> {
        const url = "companies/documents/";
        return axiosInstance.post(url, payload);
    }
}

export default CompanyService;
